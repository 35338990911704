// i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    message: {
     // 多少人申请
  SQ: 'Đã có',
  SQNumber: '8316',
  SQS: 'người đăng ký',
  // 姓名
  name: 'Họ và tên',
  name_message: 'Vui lòng nhập tên!',
  // 性别
  sex: 'Giới tính',
  sex_message: 'Vui lòng nhập giới tính!',
  sex_man: 'Nam',
  sex_woman: 'Nữ',
  // 出生日期
  birth: 'Ngày sinh',
  // 手机号码
  phone: 'Số điện thoại',
  phone_message: 'Vui lòng nhập số điện thoại!',
  phone_YZM: 'Mã xác nhận',
  phone_FS: 'Gửi mã',
  phone_Miao: 'giây',
  // 身份证号码
  User_id: 'Số CMND/CCCD',
  User_id_message: 'Vui lòng nhập số CMND/CCCD!',
  // 家庭住址
  homeaddress: 'Địa chỉ nhà',
  homeaddress_message: 'Vui lòng nhập địa chỉ nhà',
  // 身份证正面照片
  id_photo: 'Tải lên CMND/CCCD mặt trước',
  id_photo_text: 'Tải lên CMND/CCCD mặt trước',
  id_photo_message: 'Vui lòng tải lên CMND/CCCD mặt trước',
  // 身份证反面照片
  id_photos: 'Tải lên CMND/CCCD mặt sau',
  id_photo_texts: 'Tải lên CMND/CCCD mặt sau',
  id_photo_messages: 'Vui lòng tải lên CMND/CCCD mặt sau',
  // 结婚证照片
  id_love: 'Tải lên giấy đăng ký kết hôn',
  id_love_texts: 'Click vào Upload',
  id_love_messages: 'Vui lòng tải lên giấy đăng ký kết hôn',
  // 房子
  homename: 'Bất động sản',
  home_messages: 'Bạn có nhà không?',
  home_select: 'Có',
  home_none: 'Không',
  // 有房子显示上传房产证
  id_home: 'Tải lên sổ đỏ',
  id_home_texts: 'Nhấn vào đây để tải lên sổ đỏ',
  id_home_messages: 'Vui lòng tải lên sổ đỏ',
  // 车子
  carname: 'Xe hơi',
  car_messages: 'Bạn có xe không?',
  car_select: 'Có',
  car_none: 'Không',
  // 有车子显示填写车辆型号和车牌号码
  car_type: 'Loại xe',
  car_type_messmage: 'Vui lòng nhập loại xe!',
  car_number: 'Biển số xe',
  car_number_messmage: 'Vui lòng nhập biển số xe!',
  // 工作单位
  work_name: 'Đơn vị công tác',
  work_messmage: 'Vui lòng nhập đơn vị công tác!',
  // 工作单位地址
  work_ip_name: 'Địa chỉ đơn vị công tác',
  work_ip_messmage: 'Vui lòng nhập địa chỉ đơn vị công tác!',
  // 工作单位电话
  work_ip_phone: 'Số điện thoại đơn vị công tác',
  work_phone_messmage: 'Vui lòng nhập Số điện thoại đơn vị công tác!',
  // 家庭成员名字
   home_Listname: 'Tên thành viên',
  home_Listwork: 'Nghề nghiệp',
  home_Listphone: 'Điện thoại',
  home_id_Listname: 'Tên',
  home_id_Listwork: 'Công việc',
  home_id_Listphone: 'Số điện thoại',
  home_id_add: 'Thêm thành viên gia đình',
  // 银行卡号
  bank_namenumber: 'Số tài khoản ngân hàng',
  bank_namenumber_messmage: 'Vui lòng nhập số tài khoản ngân hàng!',
  // 办理的银行
  bank_type: 'Tên ngân hàng',
  bank_type_messmage: 'Vui lòng nhập Tên ngân hang bạn đang giao dịch!',
  // 银行预留手机号
  bank_number: 'Số điện thoại đăng ký với ngân hàng',
  bank_number_placeholder: 'Vui lòng nhập số điện thoại đăng ký với ngân hàng!',
  bank_number_messmage: 'Vui lòng nhập số điện thoại đăng ký với ngân hàng!',
  // 贷款金额
  bank_money: 'Số tiền vay',
  bank_money_messmage: 'Vui lòng nhập số tiền vay',
  bank_money_1: '50triệu - 1 tỷ',
  bank_money_2: '1 tỷ - 2 tỷ',
  bank_money_3: '2 tỷ - 3 tỷ',
  bank_money_4: '3 tỷ - 5 tỷ',
  // 查询进度
  Source_title: 'Kiểm tra tiến độ',
  Source_ok: 'Gửi',
  Source_tcancel: 'Hủy bỏ',
  Source_phone: 'Vui lòng nhập số điện thoại',

  // 提交按钮
  submit: 'Đăng ký ngay',
  // 公司
  GS: 'Dịch vụ 360 Tài chính online An Toàn - Uy Tín',
  GSshenqin: 'Đăng ký bảo vệ',
  GSleft: 'Hỗ trợ bảo vệ quyền lợi',
  GScenter: 'Bồi thường sai pháp lý',
  GSright: 'Bồi thường lừa đảo , giả mạo',
  GSfx: 'Lưu ý : Tránh rủi ro xảy ra khi thực hiện khoản vay khách hàng hãy lựa chọn cẩn thận !',
  KF_Message:'Trong quá trình thao tác đăng ký hồ sơ vay ,\n khách hàng thắc mắc gì cần hỗ trợ tư vấn, xin liên hệ bộ phận chăm sóc khách hàng ( CSKH ) chúng tôi sẽ hỗ trợ 24/7 dưới 3 hình thức !!!',
  KF_Message_one:'Liên hệ 1 : Số    CSKH - +84 0867304680',
  KF_Message_two:'Liên hệ 2 : Zalo   CSKH - +84 0924719579',
  KF_Message_three:'',
    }
  },
  iv: {
    message: {
      //多少人申请
      SQ: 'Already',
      SQNumber: '8316',
      SQS: 'people applied',
        //姓名
      name: 'Name',
      name_message: 'Please enter your name!',
       //性别
       sex:'Gender',
       sex_message:'Please enter your gender!',
       sex_man:'Male',
       sex_woman:'Female',
       //出生日期
       birth:'Date of Birth',
       //手机号码
       phone:'Phone Number',
       phone_message:'Please enter your phone number!',
       phone_YZM: 'Verification Code',
       phone_FS: 'Send',
       phone_Miao: 'Seconds',
       //身份证号码
       User_id:'ID Number',
       User_id_message:'Please enter your ID number!',
       //家庭住址
       homeaddress:'Home Address',
       homeaddress_message:'Please enter your home address',
       //身份证正面照片
       id_photo:'Upload Front of ID',
       id_photo_text:'Click to upload front of ID',
       id_photo_message:'Please upload front photo of ID',
        //身份证反面照片
       id_photos:'Upload Back of ID',
       id_photo_texts:'Click to upload back of ID',
       id_photo_messages:'Please upload back photo of ID',
         //结婚证照片
        id_love:'Upload Marriage Certificate',
        id_love_texts:'Click to upload Marriage Certificate',
        id_love_messages:'Please upload Marriage Certificate',
        //房子
        homename:'Property',
        home_messages:'Do you have a house!',
        home_select:'Yes',
        home_none:'No',
        //有房子显示上传房产证
        id_home:'Upload Property Deed',
        id_home_texts:'Click to upload Property Deed',
        id_home_messages:'Please upload Property Deed',
        //车子
        carname:'Car',
        car_messages:'Do you have a car!',
        car_select:'Yes',
        car_none:'No',
        //有车子显示填写车辆型号和车牌号码
        car_type:'Car Model',
        car_type_messmage:'Please enter the car model!',
        car_number:'License Plate Number',
        car_number_messmage:'Please enter license plate number',
        //工作单位
        work_name :'Workplace',
        work_messmage :'Please enter your workplace!',
        //工作单位地址
        work_ip_name :'Workplace Address',
        work_ip_messmage :'Please enter workplace address!',
        //工作单位电话
        work_ip_phone :'Work Phone Number',
        work_phone_messmage :'Please enter work phone number!',
        //家庭成员名字
        
          home_Listname: 'Family Name',
          home_Listwork: 'Occupation',
          home_Listphone: 'Phone',
        home_id_Listname:'Name',
        home_id_Listwork:'Occupation',
        home_id_Listphone:'Phone',
        home_id_add:'Add Family Member',
        //银行卡号
        bank_namenumber:'Bank Account Number',
        bank_namenumber_messmage:'Please enter your bank account number!',
        //办理的银行
        bank_type:'Bank Name',
        bank_type_messmage:'Please enter the bank name!',
        //银行预留手机号
        bank_number:'Bank Account Phone Number',
        bank_number_placeholder:'Please enter phone number associated with your bank account!',
        bank_number_messmage:'Please enter phone number associated with your bank account!',
        //贷款金额
        bank_money:'Loan Amount',
        bank_money_messmage:'Please enter loan amount',
        bank_money_1: '100M - 1B',
        bank_money_2: '1B - 2B',
        bank_money_3: '2B - 3B',
        bank_money_4: '3B - 5B',
        //查询进度
        Source_title: 'Progress Inquiry',
        Source_ok: 'Submit',
        Source_tcancel: 'Cancel',
        Source_phone: 'Please enter your phone number',
        //提交按钮
        submit:'Apply Now',
        //公司
        GS:'Dịch vụ 360 Tài chính online An Toàn - Uy Tín',
        GSlogo:'Sign in for citizen rights protection. In case of fraud, you can',
        GSshenqin:'Apply for Protection',
        GSleft:'Hỗ trợ bảo vệ quyền lợi',
        GScenter:'Bồi thường sai pháp lý',
        GSright:'Bồi thường lừa đảo , giả mạo',
        GSfx:'Lưu ý : Tránh rủi ro xảy ra khi thực hiện khoản vay khách hàng hãy lựa chọn cẩn thận !',

    }
    
  }
};

const i18n = createI18n({
  legacy: false, 
  locale: 'en', // 设置默认语言
  fallbackLocale: 'iv', // 设置备用语言
  messages,
});

export default i18n;