<template>
    <div class="sliding-layout">
      <a-spin size="large" class="mylarge" v-if="isSpinning"/>

        <div>
          <a-modal
            v-model:visible="visible"
            :title="$t('message.Source_title')"
            :ok-text="$t('message.Source_ok')"
            :cancel-text="$t('message.Source_tcancel')"
            @ok="submitForm"
            @cancel="hideModal">
            <a-form-item>
              <a-input-group compact>
                <a-input value="+84" disabled style="width: 60px; text-align: center;" />
                <a-input v-model:value="phoneNumber" :placeholder="$t('message.Source_phone')" style="width: calc(100% - 60px);" />
                <a-spin size="large" class="mylarges" v-if="isSpinnings"/>
              </a-input-group>
            </a-form-item>
          </a-modal>
          <a-modal
          v-model:visible="contactModalVisible"
          title="Trong quá trình thao tác đăng ký hồ sơ vay "
          @ok="hideContactModal"
          class="contactess"
          :ok-text="$t('message.Source_ok')"
        >
        <div class="titt">
          <span >khách hàng thắc mắc gì cần hỗ trợ tư vấn, xin liên hệ bộ phận chăm sóc khách hàng ( CSKH ) chúng tôi sẽ hỗ trợ 24/7 dưới 3 hình thức !!!</span>
        </div>

          <div class="contact-item">
            <div class="contact-itemss">
              <span>{{$t('message.KF_Message_one') }}</span>
              <a-button class="BtnSave" size="small" @click="copyToClipboard(`+84 0867304680`)">Sao chép</a-button>
            </div>
            <div class="contact-itemss">
              <span>{{$t('message.KF_Message_two') }}</span>
              <a-button class="BtnSave" size="small" @click="copyToClipboard(`+84 0924719579`)">Sao chép</a-button>
            </div>
            <div class="contact-itemss">
              <span>Liên hệ 3 : Email  360financialloans@gmail.com</span>
              <a-button class="BtnSave" size="small" @click="copyToClipboard(`www.360financialloans@gmail.com`)">Sao chép</a-button>
            </div>
          </div>
        </a-modal>
        </div>
      <!-- <div class="affix-container" :style="{ top: topValue + 'px' }"> -->
        <!-- 切换语言 -->
        <!-- <a-affix  :offset-top="120" :offsetBottom="140" @click="changeLaungue">
          <a-button class="no-border-button"><img  style="background-color: white; width: 30px; height: 30px;   border-radius: 2px;" :src="currentIcon"></a-button>
        </a-affix> -->
<!-- 
        <a-affix class="affix-container"  :offset-top="120" :offsetBottom="140" >
          <a-button class="no-border-button" @click="changeSource"><img style="background-color: white; width: 30px; height: 30px; border-radius: 2px;" src="@/assets/source.jpg"></a-button>
        </a-affix>
        <a-affix class="affix-container"  :offset-top="160" :offsetBottom="100" >
          <a-button class="no-border-button" @click="changeService"><img style="background-color: white; width: 30px; height: 30px;  border-radius: 2px;" src="@/assets/Service.jpg"></a-button>
        </a-affix> -->
      <!-- </div> -->
      <div class="image-container">
        <img :src="imagePath"/>
      </div>
      <div class="image-container">
        <img :src="imagePath2"/>
      </div>
      <div class="form-div">
        <button @click="changeSource">
          <img src="@/assets/Button_Two/source_image.jpg" alt="Button Image"/>
        </button>
        <button @click="changeService">
          <img src="@/assets/Button_Two/kefu_image.jpg" alt="Button Image"/>
        </button>
    </div>
        <div class="form-container">
      
    <div class="center">
      <fireEr />
    </div>
    <a-config-provider :locale="locales">
      <a-form
      ref="formRefs"
      :model="formState"
      layout="vertical"
      name="basic"
      autocomplete="off"
      @finish="onFinish"
      :locale="locales"
      @finishFailed="onFinishFailed"
      class="form-containers"
    >
      <a-form-item 
        :label="$t('message.name')"
        name="username"
        class="form-item"
        :rules="[{required: true, message: $t('message.name_message') }]"
      >
        <a-input :placeholder="$t('message.name_message')" class="form-itemitename" v-model:value="formState.username"/>
      </a-form-item>
  
      <a-form-item   name="resource" class="form-item" :label="$t('message.sex')" :rules="[{required: true, message: $t('message.sex_message') }]">
        <a-radio-group class="form-itemsex" v-model:value="formState.resource">
          <a-radio class="form-itemsudio" value="1">{{$t('message.sex_man') }}</a-radio>
          <a-radio class="form-itemsudio" value="2">{{$t('message.sex_woman') }}</a-radio>
        </a-radio-group>
      </a-form-item>
  
      <a-form-item  class="form-item" name="time-picker"   :label="$t('message.birth')" v-bind="config">
        <a-date-picker :locale="locales" placeholder="Chọn ngày" class="form-itemsex" v-model:value="formState['time-picker']" value-format="YYYY-MM-DD" />
      </a-form-item>



    <a-form-item
    :label="$t('message.User_id')"
    name="userid"
    class="form-item"
    :rules="[{
      required: true, 
      message: $t('message.User_id_message'),
      pattern: /^\d{9}$|^\d{12}$/,
    }]"
  >
    <a-input :placeholder="$t('message.User_id_message')" class="form-itemitename" v-model:value="formState.userid"/>
  </a-form-item>

  <!-- 家庭住址 -->
  <a-form-item 
  :label="$t('message.homeaddress')" name="homeaddress" class="form-item" :rules="[{ required: true, message: $t('message.hid_photo_message') }]">
  <a-input :placeholder="$t('message.homeaddress_message')" class="form-itemitename" v-model:value="formState.homeaddress"/>
</a-form-item>
<!--身份证正面 -->
  <a-form-item  class="form-item" name="upload" :label="$t('message.id_photo')"
  :rules="[{required: true, message: $t('message.id_photo_message') }]">
    <a-upload
      v-model:fileList="formState.upload"
      name="logo"
      action="/upload.do"
      :limit="1"
      :beforeUpload="beforeFileUpload"
      list-type="picture"
      accept="image/*"
    >
      <a-button>
        <template  #icon><UploadOutlined /></template>
        {{$t('message.id_photo_text') }}
      </a-button>
    </a-upload>
  </a-form-item>
<!--身份证反面 -->
  <a-form-item  class="form-item" name="uploads" :label="$t('message.id_photos')" :rules="[{required: true, message: $t('message.id_photo_messages') }]">
    <a-upload
      v-model:fileList="formState.uploads"
      name="logos"
      action="/upload.do"
      :limit="1"
      :beforeUpload="beforeFileUpload"
      accept="image/*"
      list-type="picture">
      <a-button>
        <template  #icon><UploadOutlined /></template>
        {{$t('message.id_photo_texts') }}
      </a-button>
    </a-upload>
  </a-form-item>
  <!--结婚证 -->


<!-- 判断是否有房子 -->
<a-form-item name="homeslect" class="form-item" :label="$t('message.homename')" :rules="[{required: true, message: $t('message.home_messages') }]">
  <a-radio-group class="form-itemsex" v-model:value="formState.homeslect">
    <a-radio class="form-itemsudio" value="1">{{$t('message.home_select')}}</a-radio>
    <a-radio class="form-itemsudio" value="2">{{$t('message.home_none')}}</a-radio>
  </a-radio-group>
</a-form-item>
<!-- 有房子显示上传房产证 -->
<a-form-item v-if="formState.homeslect === '1'"  class="form-item" name="uploadhome" :label="$t('message.id_home')" :rules="[{required: false, message: $t('message.id_hom_messages') }]">
  <a-upload
    v-model:fileList="formState.uploadhome"
    name="logouploadhome"
    action="/upload.do"
    :beforeUpload="beforeFileUpload"
    :limit="1"
    list-type="picture">
    <a-button>
      <template  #icon><UploadOutlined /></template>
      {{$t('message.id_home_texts')}}
    </a-button>
  </a-upload>
</a-form-item>

<!-- 判断是否有车子 -->
<a-form-item name="carselect"  class="form-item" :label="$t('message.carname')" :rules="[{required: true, message: $t('message.car_messages') }]">
  <a-radio-group class="form-itemsex" v-model:value="formState.carselect">
    <a-radio class="form-itemsudio" value="1">{{ $t('message.car_select') }}</a-radio>
    <a-radio class="form-itemsudio" value="2">{{ $t('message.car_none') }}</a-radio>
  </a-radio-group>
</a-form-item>
<!-- 有车子显示填写车辆型号和车牌号码-->
      <a-form-item v-if="formState.carselect === '1'" :label="$t('message.car_type')" name="cartype" class="form-item" :rules="[{required: false, message: $t('message.car_type_messmage') }]">
        <a-input :placeholder="$t('message.car_type_messmage')" class="form-itemitename" v-model:value="formState.cartype"/>
      </a-form-item>
      <a-form-item v-if="formState.carselect === '1'" :label="$t('message.car_number')" name="carnumber" class="form-item" :rules="[{required: false, message: $t('message.car_number_messmage') }]">
        <a-input :placeholder="$t('message.car_number_messmage')" class="form-itemitename" v-model:value="formState.carnumber"/>
      </a-form-item>

<!-- 工作单位-->
 <a-form-item :label="$t('message.work_name')" name="workunit" class="form-item" :rules="[{ required: false, message: $t('message.work_messmage') }]">
  <a-input :placeholder="$t('message.work_messmage')" class="form-itemitename" v-model:value="formState.workunit"/>
</a-form-item>
<!-- 工作单位地址-->
 <a-form-item :label="$t('message.work_ip_name')" name="workaddress" 
 class="form-item" :rules="[{ required: false, message: $t('message.work_ip_messmage') }]">
  <a-input :placeholder="$t('message.work_ip_messmage')" class="form-itemitename" v-model:value="formState.workaddress"/>
</a-form-item>
 <a-form-item
      :label="$t('message.work_ip_phone')"
      name="workphone"
      class="form-item"
      :rules="[{
        required: false, 
        message: $t('message.work_phone_messmage'),
        pattern:/^\d{9}$/,
      }]">
      <!--  -->
      <a-input :placeholder="$t('message.work_phone_messmage')" class="form-itemitename" v-model:value="formState.workphone"/>
    </a-form-item>

    <div class="containers">
      <div class="text-item">{{$t('message.home_Listname')}}</div>
      <div class="text-item">{{$t('message.home_Listwork')}}</div>
      <div class="text-item">{{$t('message.home_Listphone')}}</div>
    </div>
  <!-- 实时添加表格 -->
  <a-space
  v-for="(user, index) in formState.users"
  :key="user.id"
  style="display: flex; margin-bottom: 8px"
  align="baseline"
>
  <a-form-item
    :name="['users', index, 'first']"
    :rules="{
      required: true,
      message: 'Thiếu tên',
    }"
  >
    <a-input v-model:value="user.first" :placeholder="$t('message.home_id_Listname')" />
  </a-form-item>
  <a-form-item
    :name="['users', index, 'last']"
    :rules="{
      required: true,
      message: 'Thiếu việc làm',
    }"
  >
    <a-input v-model:value="user.last" :placeholder="$t('message.home_id_Listwork')" />
  </a-form-item>
  <a-form-item
  :name="['users', index, 'phone']"
  :rules="{
    required: true,
    message: 'Thiếu số điện thoại',
  }"
>
  <a-input v-model:value="user.phone" :placeholder="$t('message.home_id_Listphone')" />
</a-form-item>
  <MinusCircleOutlined @click="removeUser(user)"/>
</a-space>
<a-form-item>
  <a-button type="dashed" block @click="addUser">
    <PlusOutlined />
    {{$t('message.home_id_add')}}
  </a-button>
</a-form-item>

 <a-form-item
      :label="$t('message.bank_namenumber')"
      name="banknumber"
      class="form-item"
      :rules="[{
        required: true, 
        message: $t('message.bank_namenumber_messmage'),
      }]"
    >
      <a-input :placeholder="$t('message.bank_namenumber_messmage')" class="form-itemitename" v-model:value="formState.banknumber"/>
    </a-form-item>

    <a-form-item
    :label="$t('message.bank_type')"
    name="bankname"
    :rules="[{ required: true, message: $t('message.bank_type_messmage') }]"
    class="form-item">
    <a-input :placeholder="$t('message.bank_type_messmage')" class="form-itemitename" v-model:value="formState.bankname"/>
  </a-form-item>
  <a-form-item :label="$t('message.bank_number')" name="banknamephone" class="form-item" :rules="[{required: true, message:$t('message.bank_number_messmage'),   pattern:/^\d{9}$/,}]">
  <a-input :placeholder="$t('message.bank_number_placeholder')" class="form-itemitename" v-model:value="formState.banknamephone"/>
</a-form-item>

  <a-form-item  class="form-item"  :label="$t('message.bank_money')" :rules="[{required: true, message: $t('message.bank_money_messmage') }]">
    <a-radio-group  v-model:value="formState.layout">
      <a-radio-button class="group" value="one">{{$t('message.bank_money_1')}}</a-radio-button>
      <a-radio-button class="group"  value="two">{{$t('message.bank_money_2')}}</a-radio-button>
      <a-radio-button class="group"  value="three">{{$t('message.bank_money_3')}}</a-radio-button>
      <a-radio-button class="group"  value="four">{{$t('message.bank_money_4')}}</a-radio-button>
    </a-radio-group>
  </a-form-item>
  <a-form-item
  :label="$t('message.phone')"
  name="useriphone"
  class="form-item"
  :rules="[{
    required: true, 
    validator: validatePhone
  }]"
>
<!--        pattern:/^\d{8}$/,-->
<a-input-group compact>
  <a-input style="width: 20%" value="+84" disabled />
  <a-input  style="width: 80%" :placeholder="$t('message.phone_message')" v-model:value="formState.useriphone"/>
</a-input-group>
</a-form-item>
<a-form-item name="verificationCode">
  <a-input-group compact>
    <a-input style="width: 70%; height:35px" :placeholder="$t('message.phone_YZM')" v-model:value="formState.verificationCode"/>
    <a-button 
      style="width: 30%; height:35px" 
      :disabled="timeLeft > 0" 
      @click="sendVerificationCode">
      {{ timeLeft > 0 ? `${timeLeft} ${$t('message.phone_Miao')}` : $t('message.phone_FS') }}
    </a-button>
  </a-input-group>
</a-form-item>
  <a-form-item >
    <a-button   class="start" type="primary" html-type="submit">{{$t('message.submit')}}</a-button>
  </a-form-item>
    </a-form>
  </a-config-provider>
<div class="ListCenter">
  <fireEr :should-show-image="false"/>
  <Listname class="ListCol"/>
</div>
<!-- 轮播图 -->
<a-carousel autoplay :autoplaySpeed="2000"  style="height: 180px;">
  <div><img class="images" src="@/assets/yhang/yh1.png"  /></div>
  <div><img class="images" src="@/assets/yhang/yh2.png" /></div>
  <div><img class="images" src="@/assets/yhang/yh3.png"  /></div>
  <div><img class="images" src="@/assets/yhang/yh4.png" /></div>
  <div><img class="images" src="@/assets/yhang/yh5.png"  /></div>
  <div><img class="images" src="@/assets/yhang/yh6.png" /></div>
  <div><img class="images" src="@/assets/yhang/yh7.png"  /></div>
  <div><img class="images" src="@/assets/yhang/yh8.png" /></div>
  <div><img class="images" src="@/assets/yhang/yh9.png"  /></div>
  <div><img class="images" src="@/assets/yhang/yh10.png" /></div>
</a-carousel>

    <div class="image-container">
      <img :src="imagePath3"/>
    </div>
    <div class="image-container">
      <img :src="imagePath4"/>
    </div>
    <div  class="footer-container">
       <h3  class="name">{{$t('message.GS')}}</h3> 
      <ul  class="sign-text">
        <div  class="icon-safeguarding-rights guarantee-icon-new"><img class="bottomima" src="@/assets/ImageChinese/dunpai.png">{{$t('message.GSleft')}}
      </div><div  class="icon-false-compensation guarantee-icon-new"><img class="bottomima" src="@/assets/ImageChinese/r.png" >{{$t('message.GScenter')}}
      </div><div  class="icon-fraud-compensation guarantee-icon-new"><img class="bottomima" src="@/assets/ImageChinese/qian.png" >{{$t('message.GSright')}}
      </div>
    </ul> 
      <div  class="trade-warning-info">
         <div  class="reminder-item-block">
        <div  class="reminder-item">{{$t('message.GSfx')}}</div>
      </div></div></div>
  </div>
     </div>
  </template>
  
  <script >
  import { defineComponent, reactive ,watch, ref,onMounted,computed,createVNode} from 'vue';
  import { UploadOutlined, PlusOutlined,MinusCircleOutlined,ExclamationCircleOutlined } from '@ant-design/icons-vue';
  import { message,Modal } from 'ant-design-vue'; 
  import fireEr from '@/views/FiresEr.vue'; 
  import Listname from '@/views/ThereView.vue'; 
  import { useI18n } from 'vue-i18n';
  // import ENImage from '@/assets/ImageChinese/EN.jpg'; // 假设这是英语图标
  // import VIImage from '@/assets/ImageChinese/VI.jpg'; 
  //贷款金额
  import VIImageTop from '@/assets/ImageChinese/top.png'; 
  import ENImageTop from '@/assets/ImageChinese/top.png'; 
  //贷款详情
  import VIImageTwo from '@/assets/ImageChinese/Two.jpg'; 
  import ENImageTwo from '@/assets/ImageChinese/Two.jpg'; 
    //贷款详情
  import VIImageThree from '@/assets/ImageChinese/Three.jpg'; 
  import ENImageThree from '@/assets/ImageChinese/Three.jpg'; 
   //四个按钮
   import VIImageFour from '@/assets/ImageChinese/fours.png'; 
  import ENImageFour from '@/assets/ImageChinese/fours.png'; 

  import { useRouter } from 'vue-router';
  //日历国际化
  import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import vi_VN from 'ant-design-vue/es/locale/vi_VN';
dayjs.locale('vi');
//全局变量

export default defineComponent({
  components: {
    UploadOutlined,
      fireEr,
      Listname,
      MinusCircleOutlined,
      PlusOutlined,
  },
  name: 'SlidingLayout',
  setup() {
//时间国际化
// eslint-disable-next-line no-unused-vars
const locales = ref(vi_VN.locale);


//绑定表单
const formRefs = ref(null);
//重复手机号
const PhoneValid = ref('111111111111');
//手机号动态表达式
const validatePhone = (rule, value) => {
      if (value === PhoneValid.value) {
        return Promise.reject('Số điện thoại trên đã được sử dụng đăng ký , vui lòng xác minh lại !');
      }
      if (!/^\d{9}$/.test(value)) {
        return Promise.reject('Số điện thoại phải có 9 chữ số');
      }
      return Promise.resolve();
    };
    //菊花加载
    const isSpinning = ref(false);
     // 定义显示和隐藏方法
     const showSpinner = () => {
      isSpinning.value = true;
    };

    const hideSpinner = () => {
      isSpinning.value = false;
    };
     //第二菊花加载
     const isSpinnings = ref(false);
     // 定义显示和隐藏方法
     const showSpinners= () => {
      isSpinnings.value = true;
    };

    const hideSpinners= () => {
      isSpinnings.value = false;
    };
//首页第一张图片切换
const imagePath = computed(() => {
      return locale.value === 'en' 
        ? VIImageTop
        : ENImageTop;
    });
//首页第二张图片切换
const imagePath2 = computed(() => {
      return locale.value === 'en' 
        ? VIImageTwo
        : ENImageTwo;
    });
//首页第三张图片切换
const imagePath3 = computed(() => {
      return locale.value === 'en' 
        ? VIImageThree
        : ENImageThree;
    });
//首页第四张图片切换
const imagePath4 = computed(() => {
      return locale.value === 'en' 
        ? VIImageFour
        : ENImageFour;
    });


    //三个联系方式
    const contactModalVisible = ref(false);
    const hideContactModal = () => {
      contactModalVisible.value = false;
     
    };

    const copyToClipboard = text => {
      if (navigator.clipboard && window.isSecureContext) {
        message.success('Sao chép thành công');
        return navigator.clipboard.writeText(text);
   
      } else {
        return Promise.reject('Clipboard not available');
      }
    };
    //查询手机号
    const phoneNumber = ref('');
    //弹窗
    const visible = ref(false);
    const inputValue = ref('');

    const showModal = () => {
      visible.value = true;
    };

    const hideModal = () => {
      visible.value = false;
    };

    const submitForm = () => {
      // 这里处理提交逻辑
      showSpinners();
      getNumberStatus();
      console.log('输入值:', phoneNumber);
      // hideModal();
    };

    const confirm = () => {
      Modal.confirm({
        title: 'Confirm',
        icon: createVNode(ExclamationCircleOutlined),
        content: 'Bla bla ...',
        okText: 'Xác nhận',
        cancelText: 'Hủy bỏ',
      });
    };
    
    //验证码
    const timeLeft = ref(0);
    //响应数据
    const data = ref(null); // 用于存储响应数据
    const error = ref(null); 
    //手机号
   //响应数据方法
   const fetchData = async () => {
      try {
        let cleanPhoneNumber = formState.useriphone.trim();
        console.log(`https://con.360taichinh.com/user/sendVerification?number=84${cleanPhoneNumber}`);
        const response = await fetch(`https://con.360taichinh.com/user/sendVerification?number=84${cleanPhoneNumber}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
    if (responseData.status === 10006) {
      message.error('Số điện thoại trên đã được sử dụng đăng ký , vui lòng xác minh lại !');
      PhoneValid.value = formState.useriphone;
     
    }else if(responseData.status != 1000){
      message.error(responseData.msg);
    }
        console.log(data.value);
      } catch (err) {
       
        error.value = err.message;
        console.log( err.message);
      }
    };
     //获取验证码方法
    const sendVerificationCode = () => {
      if(formState.useriphone != ''){
      timeLeft.value = 60;
      fetchData();
      const interval = setInterval(() => {
        if (timeLeft.value > 0) {
          timeLeft.value -= 1;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    }else{
      message.error('Vui lòng điền đúng số điện thoại !');
    }
    };
    //语言
    const { locale } = useI18n();
    const changeLaungue = affixed => {
      if(locale.value  == 'en'){
        locale.value = 'iv';

      }else{
        locale.value = 'en';
      }
      console.log('1111111',affixed);
    };
    const changeSource = affixed => {
      visible.value = true;
      console.log("222222",affixed);
    };
    const changeService = affixed => {
      contactModalVisible.value = true;
      console.log(affixed);
    };

    // const currentIcon = computed(() => {
    //   return locale.value === 'en' ? ENImage : VIImage; // 假设 VI.jpg 是越南语图标
    // });

    const topValue = ref(0);
    onMounted(() => {
      const screenHeight = window.innerHeight;
      topValue.value = screenHeight - 100;
    });
    //添加表格
    const formRef = ref();

    const removeUser = item => {
      let index = formState.users.indexOf(item);
      if (index !== -1) {
        formState.users.splice(index, 1);
      }
    };
    const addUser = () => {
      formState.users.push({
        first: '',
        last: '',
        phone: '',
        id: Date.now(),
      });
    };
    //跳转
    const router = useRouter();

    const onFinish = values => {
      console.log("成功");
      submitData();
      showSpinner();
      // router.push('/succe_s', params: { param: 'value' });
      // router.push({ name: 'SuccessPage', params: { param: 'success' } });
      console.log('Received values of form:', values);
      // console.log('formState.users:', formState.users);
    };
//提交数据
 const usePostRequest = async (url, data) => {  
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const responseData = await response.json();
    hideSpinner();
    if (response.ok) {
      // 处理成功的情况
  if(responseData.msg === 'success'){
    console.log('Operation was successful');
      router.push({ name: 'SuccessPage', params: { param: 'success' } });
  }else{
    message.error(responseData.msg);
  }
   
      return responseData;
    } else {
      message.error('Vui lòng kiểm tra các thông số của bạn');
       console.log('Operation was successful');
      throw new Error(responseData.msg || 'Unknown error');
    }
    // return response.json();
  } catch (error) {
    hideSpinner();
    message.error('Vui lòng kiểm tra mạng của bạn');
    console.error('There was a problem with the fetch operation:', error);
    // message.error('Vui lòng kiểm tra mạng của bạn');
    throw error;
  }
};
//用户数据模型--调用就可
const submitData = async () => {
  let selectedBankMoneyText;
  console.log("成功222222");
switch (formState.layout) {
  case 'one':
    selectedBankMoneyText = '50triệu - 1 tỷ';
    break;
  case 'two':
    selectedBankMoneyText = '1 tỷ - 2 tỷ';
    break;
  case 'three':
    selectedBankMoneyText =  '2 tỷ - 3 tỷ';
    break;
  case 'four':
    selectedBankMoneyText =  '3 tỷ - 5 tỷ';
    break;
  default:
    selectedBankMoneyText = 'none'; // 或者任何默认值
}
console.log("成功333333");
      const url = 'https://con.360taichinh.com/user/addUser';
      const data = {
    "name":formState.username,
    "sex":parseInt(formState.resource),
    "brith":formState['time-picker'],
    "phone":"84" +formState.useriphone,
    "idCard":formState.userid,
    "address":formState.homeaddress,
    "workSpace":formState.workunit,
    "workAddress":formState.workaddress,
    "workPhone":formState.workphone,
    "familyMember":JSON.stringify(formState.users),
    "haveHouse":parseInt(formState.homeslect),
    "haveCar":parseInt(formState.carselect),
    "carNumber":formState.cartype,
    "licensePlateNumber":formState.carnumber,
    "idcardsImg1":formState.upload && formState.upload.length > 0 ? formState.upload[0].thumbUrl : '',
    "test":formState.uploads && formState.uploads.length > 0 ? formState.uploads[0].thumbUrl : '',
    "marriageCertificate":formState.uploadhome && formState.uploadhome.length > 0 ? formState.uploadhome[0].thumbUrl : '',
    "titleDeed":formState.uploadhome && formState.uploadhome.length > 0 ? formState.uploadhome[0].thumbUrl : '',
    "bankNumber":formState.banknumber,
    "bankName":formState.bankname,
    "bankPhone":formState.banknamephone,
    "wantAmount":selectedBankMoneyText,
    "captcha":formState.verificationCode
      };
      console.log(data);
      //    "BankPhone":formState.banknamephone,
console.log("成功444444444444");

      try {
        hideSpinner();
        const responseData = await usePostRequest(url, data);
        console.log(responseData);
        console.log("成功-----正在执行");
      } catch (error) {
        hideSpinner();
        console.log("成功-----执行错误");
      }
    };
    //获取手机信息
    function getNumberStatus() {
  return new Promise((resolve, reject) => {
    console.log('getNumberStatus called'); // 初始调用
    let cleanPhoneNumber = phoneNumber.value.trim(); // 获取并清理电话号码
    console.log('Cleaned phone number:', cleanPhoneNumber); // 输出清理后的电话号码
    
    fetch(`https://con.360taichinh.com/user/getNumberStatus?number=84${cleanPhoneNumber}`) // 发起 GET 请求
      .then(response => {
        console.log('Fetch response received'); // 收到响应
        hideSpinners(); // 隐藏加载动画
        console.log('Spinner hidden'); // 确认隐藏加载动画
        return response.json(); // 解析 JSON 响应
      })
      .then(data => {
        console.log('JSON parsed:', data); // 输出解析后的 JSON 数据
        hideSpinners(); // 隐藏加载动画
        console.log('Spinner hidden again'); // 确认隐藏加载动画

        if (data.status === 10012) {
          console.error('Operation failed with status 10012:', data.msg); // 错误信息
          message.error(data.msg); // 显示错误信息
          reject(new Error(data.msg)); // 拒绝 Promise 并传递错误
        } else if (data.msg === 'success') {
          console.log('Operation succeeded:', data.data); // 操作成功

          if (data.data == -1) {
            console.log('Routing to lodding'); // 路由跳转到 lodding
            router.push({ name: 'SuccessPage', params: { param: 'lodding' } });
          } else if (data.data == 0) {
            console.log('Routing to fail'); // 路由跳转到 fail
            router.push({ name: 'SuccessPage', params: { param: 'fail' } });
          } else {
            console.log('Routing to success'); // 路由跳转到 success
            router.push({ name: 'SuccessPage', params: { param: 'success' } });
          }

          resolve(data); // 成功解析，返回数据
        } else {
          console.error('Operation failed with unknown status:', data.msg); // 其他错误信息
          message.error(data.msg); // 显示错误信息
          reject(new Error(data.msg)); // 拒绝 Promise 并传递错误
        }
      })
      .catch(error => {
        hideSpinners(); // 隐藏加载动画
        console.error('Catch block error:', error); // 捕获错误信息
        message.error('Network error');
        alert('Network error: ' + error.message); // 显示网络错误
        reject(error); // 拒绝 Promise 并传递错误
      });
  });
}
    const formState = reactive({
      //用户名
      username: '',
      //手机号
      useriphone: '',
      //验证码
      verificationCode: '',
      //身份证
      userid:'',
      //性别
      resource:'',
      //家庭住址
      homeaddress:'',
      //房产证
      uploadhome:[],
      //时间
      'time-picker': '',
      //工作单位
      workunit:'',
      //工作号码
      workphone:'',
      //工作单位地址
      workaddress:'',
      //是否有房子
      homename:'',
      upload: [],
      uploads: [],
      banknumber:'',
      bankname:'',
      banknamephone:'',
      homeslect:'',
      carselect:'',
      cartype:'',
      carnumber:'',
      users: [{ first: '', last: '', phone: '', id: Date.now() }],
    });
    watch(() => formState.upload, (newFileList) => {
      if (newFileList.length > 1) {
        message.error('Chỉ có thể tải lên một ảnh mặt trước của ID');
        // Remove the last added file
        formState.upload.pop();
      }
    });
    watch(() => formState.uploads, (newFileList) => {
      if (newFileList.length > 1) {
        message.error('Chỉ có thể tải lên một ảnh ngược của ID');
        // Remove the last added file
        formState.uploads.pop();
      }
    });
    //uploadlove
 
    watch(() => formState.uploadhome, (newFileList) => {
      if (newFileList.length > 1) {
        message.error('no');
        // Remove the last added file
        formState.uploadhome.pop();
      }
    });
    const config = {
      rules: [{
        type: 'string',
        required: true,
        message: 'Please select time!',
      }],
    };
  
    const beforeFileUpload = (file) => {
  return new Promise((resolve, reject) => {
    // 检查文件大小是否小于200KB
    const isLt2KB = file.size / 1024 < 200;
    if (!isLt2KB) {
      console.log('上传文件大于200KB!');

      reject(false);
    }

    // 检查是否已经上传了文件
    if (this.fileList && this.fileList.length > 0 || this.isFlag >= 1) {
      console.log('只能上传一张图片！');
      reject(false);
    }

    // 检查文件类型是否为图片
    if (!file.type.match('image.*')) {
      console.log('只能上传图片文件！');
      reject(false);
    }

    // 检查图片尺寸
    const _URL = window.URL || window.webkitURL;
    const img = new Image();
    img.onload = () => {
      const valid = img.width >= 400 && img.width <= 1500 && img.height >= 400 && img.height <= 1500;
      if (!valid) {
        console.error('上传图片像素应在 "400x400" 到 "1500x1500" 之间!');
        reject(false);
      } else {
        resolve(true);
      }
    };
    img.src = _URL.createObjectURL(file);
  });
};


    const onFinishFailed = errorInfo => {
      message.error('Vui lòng kiểm tra các thông số của bạn');
      console.log('Failed:', errorInfo);
    };
  
    return {
      formState,
      onFinish,
      onFinishFailed,
      config,
      beforeFileUpload,
      submitData,
      formRef,
      removeUser,
      addUser,
      changeLaungue,
      topValue,
      changeSource,
      changeService,
      // currentIcon,
      timeLeft, 
      sendVerificationCode,
      visible,
      inputValue,
      showModal,
      hideModal,
      submitForm,
      confirm,
      phoneNumber,
      contactModalVisible,
      hideContactModal,
      copyToClipboard,
      locale,
      imagePath,
      imagePath2,
      imagePath3,
      imagePath4,
      data,
      error,
      isSpinning, showSpinner, hideSpinner,
      isSpinnings, showSpinners, hideSpinners,
      PhoneValid,validatePhone,formRefs,
      locales:vi_VN
    };
  },

});
  </script>
  
  <style  scoped>
  input {
    font-size: 16px; /* 或更大 */
  }
  .sliding-layout {
    width: 100%;
    position: relative;
  }
  
  .image-container img {
    width: 100%;
    display: block; /* 这将防止图片默认的内联间距 */
  }
  
  
  .form-container {
    
    width: 100%;
    background-color: #fff;        /* 或者你想要的任何宽度 */

  }
  .form-containers {
    background-color: #fff;  
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;      /* 或者你想要的任何宽度 */
    margin: 0 auto;  
  }



  .form-item{
    margin-bottom: 20px;
    font-weight: 800;
  }

label{
    font-weight: 400;
  }

  .form-itemitename {
    height: 40px;
    display: flex;
    justify-content: flex-start;
  }
  .form-itemsex{
    width: 150px;
    height: 30px;
    display: flex;
    justify-content: flex-start;
  }

  
  .form-itemsudio  {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .form-itemupload{
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }
  .group{
    width: 46%;
    margin:5px 2%;
    border-radius: 2px;
  }
 .form-group{
  display: flex;
  justify-content: flex-start;
  text-align: left;
  
 }
 .start{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: -webkit-linear-gradient(45deg, rgb(28, 120, 249), rgb(3, 192, 255));
  width: 100%; height: 2.75rem; line-height: 2.75rem; background-color: rgb(28, 120, 249);
  margin-right: auto; margin-bottom: 0px; margin-left: auto; border-radius: 1.5rem;
  text-align: center;

  animation: pulse 2s infinite ease-in-out;
  position: relative;
  overflow: hidden; /* 防止伪元素溢出 */
 }
   
 @keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes highlight-btn {

  0%,
  80% {
      transform: translate(-220px, -50%) rotate(135deg)
  }

  to {
      transform: translate(220px, -50%) rotate(135deg)
  }
}


.start::before {
  content: '';
  position: absolute;
  top: 100%; /* 将光线定位到元素中心 */
  left: 0;
  right: 0;
  height: 10px; /* 调整高度以改变光线粗细 */
  background: linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  animation: highlight-btn 5s linear infinite;
  background-size: 100% 100%;
}
.footer-container{
     width: 100%;
    text-align: center;
    font-weight: normal;
    font-family: PingFangSC-Regular;
    font-size: 0.67934783rem;
    color: #858585;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
margin-bottom: 4px;
}
.name{
  
  margin-bottom: 0;
  color: #858585;


}
.tips{
  margin-top: 0px;
  height: 20px;
  font-size: 0.72rem;
  font-weight: 500;
  margin-bottom: -5px;
}
.icon-guarantee-text{
  margin-right: 4px;

}
.mip-sjh-footer-to-sign{
  color: blue;
  font-size: 0.63rem;
}
.sign-text{
  display: flex; /* Makes .sign-text a flex container */
  justify-content: center; /* Centers children horizontally in the container */
  align-items: center; 
  font-size: 0.50rem;
}
.guarantee-icon-new{
  height: 20px;
  margin-right: 1.01902174rem;
  display: flex; /* Makes .sign-text a flex container */
  justify-content: center; /* Centers children horizontally in the container */
  align-items: center; 
  font-size: 0.50rem;
  justify-content: center; 
  align-items: center;
}
.reminder-item{
  font-size: 0.60rem;
  margin-bottom: 4px;
}
.bottomima{
  height: 16px;
  width: 16px;
margin-right: 2px;
}
.center{
  width: 100%;
  height: 60px;
background-color: #fff;
}
.ListCenter{
  width: 90%;
  height: 200px;
  border-radius: 10px;
  background-color: rgb(240, 244, 254);
  margin-bottom: 20px;
  padding-bottom: 10px;
  margin-left: 5%;
  margin-top: 10px;
}
.ListCol{
  width: 96%;
  margin-left: 2%;
  height: 140px;
}
.containers {
  display: flex;         /* 使用 Flexbox 布局 */
  height: 40px;   
  background-color: white;       /* 容器高度设置为 40px */
}

.text-item {
  flex: 1;               /* 每个文本项都占据相同的空间 */
  text-align: left;      /* 文本靠左对齐 */
  padding-left: 10px;    /* 可选：在文本项的左侧添加一些内边距，以便有更好的视觉效果 */
}
.affix-container {
  position: absolute;
  background: transparent;
  width: 35px; /* 根据需要调整宽度 */
  right: 25px;
  display: flex; /* 启用 flex 布局 */
  flex-direction: column; /* 子元素垂直排列 */
  align-items: center; /* 水平居中对齐 */
}

/* 当 a-affix 固定时，可能需要额外的样式调整 */
.no-border-button {
  border: none; /* 移除边框 */
  box-shadow: none; /* 如果需要，也移除阴影 */
  background: transparent;
  width: 30px; /* 设置按钮宽度 */
  height: 30px; /* 设置按钮高度 */
}
.contact-itemss{
  margin-bottom: 10px;

}
.BtnSave{
  background-color: rgb(169, 203, 239);
  color: #fff;

}
.titt{
  padding-top: 0px;
  padding-bottom: 20px;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
.contact-item span {
  margin-right: 15px;
  font-size: 13px;
}
 /* 
  轮播图 
 */
:deep(.slick-slide) {
  text-align: center;
  height: 160px;
  line-height: 160px;
  
  overflow: hidden;
}
:deep(.slick-slide h3) {
  color: #fff;
}
.mylarge{
  position: absolute;
  top: 68%; /* 将文本垂直居中 */
  left: 50%; /* 将文本水平居中 */
  z-index: 5;
}
.mylarges{
  position: absolute;
  top: 20%; /* 将文本垂直居中 */
  left: 50%; /* 将文本水平居中 */
  z-index: 5;
}
.images{
  background-size: contain; /* 或者使用 'contain' */
  background-position: top;
  width: 100%;
  height: 180px
}
.form-div{
background-color: #fff;
}
button {
  border: none; /* 移除边框 */
  background: none; /* 透明背景 */
  padding: 2px 0; /* 移除内边距 */
  cursor: pointer; /* 将鼠标光标改为指针形状 */
  height: 80px;
}

button img {
  /* 可以在这里为图片设置尺寸 */
  width: 70%; /* 例如，设置宽度为 100px */
  height:auto; /* 高度自动 */
}
  </style>