<template>
    <div class="container">
      
      <div class="item" v-for="(top, index) in topValues" :key="index" 
           :style="{ top: top + 'px', visibility: topbool[index] ? 'visible' : 'hidden' }">
        <img src="@/assets/ImageChinese/laba.png" alt="Image">
        <span class="Textname" >{{ StrPath[index].name }}</span>
 
        <span class="Textphone" >{{ StrPath[index].phone }}</span>

        <span class="Texttime" >{{ StrPath[index].time }}</span>
  
      </div>
    </div>
  </template>
  <style>
.container {
    position: relative; /* 父容器设置为相对定位 */
    height: 108px;
    width:100%;
    overflow: hidden;
    display: flex;
justify-content: center;
background-color: rgb(240, 244, 254);
  }
  
  .item {
    position: absolute;
    width: 88%;
    height: 36px;
    display: flex;
       border-radius: 20px; 
    font-size: 0.775rem;
    align-items: center;
    transition: top 2.0s ease-in-out; /* 平滑过渡动画 */
    opacity: 0.4;
    padding: 0 10px;
  }
  .item img {
    width: 16px;
    height: 16px;
  }
  .Textname{
    margin-left: 10px;
  }
  .Textphone{
    margin-left: auto;
  }
  .Texttime{
    margin-left: auto;
 
  }




  @keyframes highlightAnimation {
    0% {

        background-color: transparent;
    }
    50% {
        background-color: rgb(218, 219, 253); /* 您可以选择您想要的颜色 */
        transform:scale(1.05);
        opacity: 1;
    }

    100%{
      background-color: transparent;
      opacity: 0.4;
  }
}

.item.highlight {
    animation: highlightAnimation 8s ease-in-out;
}
</style>

<script>
import { defineComponent, ref, onMounted,reactive,nextTick, onBeforeUnmount,computed } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  setup() {
    const intervalId = ref(null);
    const { locale } = useI18n();

    const topValues = ref([0, 36, 72, 108, 144, 180]);
    const topbool = reactive({0: true, 1: true, 2: true, 3: true, 4: true, 5: true});

//轮播图
const StrPath = computed(() => {
      return locale.value === 'en' 
        ? items
        : item;
    });
    const item = [
{"name":"000000James S*h**","phone":"+1-555-123-****","time":"Three days ago"},
{"name":"Emily J*n","phone":"+1-555-234-****","time":"Two day before"},
{"name":"Michael B****n","phone":"+1-555-345-****","time":"Two days ago"},
{"name":"Linda D*s**","phone":"+1-555-456-****","time":"Two days ago"},
{"name":"Robert M****r","phone":"+1-555-567-****","time":"The day before"},
{"name":"Sarah W****n","phone":"+1-555-678-****","time":"The day before"}]; 
const items = [
  {"name":"Nguyễn văn ***","phone":"09***4673","time":"Đăng ký một giờ trước"},
  {"name":"Lê thị yên ***","phone":"03***2371","time":"Đăng ký một ngày trước"},
  {"name":"Như hải ***","phone":"09***2456","time":"Đăng ký một ngày trước"},
  {"name":"Nguyễn ***","phone":"09***3577","time":"Đăng ký hai ngày trước"},
  {"name":"Đỗ minh ***","phone":"03***1238","time":"Đăng ký ba ngày trước"},
  {"name":"Tạ văn ***","phone":"09***4673","time":"Đăng ký ba ngày trước"}
]; 

    function moveItems() {
  const newTopValues = topValues.value.map((top, index) => {
    let newTop = top - 36;
    if (newTop < - 36) {
      topbool[index] = false;
      newTop = 144; // 重置到底部
    } else if (newTop == 108) {
      topbool[index] = true; // 在顶部设置为可见
    }
    if (newTop == 72) {
            triggerAnimation(index);
        }
    return newTop;
  });
  topValues.value = newTopValues;
}


function startInterval() {
      if (intervalId.value === null) {
        intervalId.value = setInterval(moveItems, 3000);
      }
    }

    function stopInterval() {
      if (intervalId.value !== null) {
        clearInterval(intervalId.value);
        intervalId.value = null;
      }
    }

    function handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        startInterval();
      } else {
        stopInterval();
      }
    }


function triggerAnimation(index) {
      nextTick(() => {
        const element = document.querySelector(`.item:nth-child(${index + 1})`);
        if (element) {
          element.classList.add('highlight');
          // 动画完成后移除类
          setTimeout(() => {
            element.classList.remove('highlight');
          }, 8000); // 动画持续时间
        }
      });
    }
    onMounted(() => {
      startInterval();
      document.addEventListener('visibilitychange', handleVisibilityChange);
    });
    onBeforeUnmount(() => {
      stopInterval();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    });
    return {
      topValues,
      topbool,
      items,
      moveItems,
      item,
      StrPath,
      locale
    };
  },
});
</script>