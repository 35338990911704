import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomeView.vue'; // 假设您有一个主页组件
import SuccessPage from '../views/success_s.vue';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/succe_s:param',
    name: 'SuccessPage',
    component: SuccessPage,
    props: true
  }
  // 在这里可以添加更多路由
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;