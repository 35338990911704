<template>
    <div class="center-div">
        <div class="oval">
          <img v-if="shouldShowImage" src="@/assets/ImageChinese/fire.png" alt="内部图片" class="inner-image" />
            <div class="text text-one">{{$t('message.SQ')}}</div>
            <div class="text text-two">{{$t('message.SQNumber')}}</div>
            <div class="text text-three">{{$t('message.SQS')}}</div>
        </div>
      </div>
</template>

<script>
export default {
    props: {
        // 定义一个名为 shouldShowImage 的 prop
        shouldShowImage: {
            type: Boolean,
            default: true 
        }
    }
}
</script>
<style>
.center-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
  }
  
  .oval {
    display: flex;
    align-items: center;
    background-color: rgba(28, 120, 249, 0.05);
    border-radius: 20px; /* 高度的一半，使其成为椭圆形 */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* 可选 */
    height: 36px;
    color: rgb(28, 120, 249);
  }
  
  .inner-image {
    width: 16px;
    height: 16px;
    margin-left: 10px;
  
  }
  
  .text-content {
    display: flex;
    flex-direction: column;
  }
  
  .text {
    font-size: 0.85rem;
  }
  
  .text-one {
    margin-left: 10px;
    /* 文本一的附加样式 */
  }
  
  .text-two {
    background-image: linear-gradient(45deg, rgba(28,120,249,1), rgb(3, 192, 255)) !important;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    padding: 5px; /* 根据需要调整填充 */
    border-radius: 0.375rem;
    padding: 0.02rem 0.375rem;
    margin: 0 0.375rem;
    font-size: 1.01875rem;
  }
  
  .text-three {
   margin-right: 15px;
  }

</style>