<template>

  <div class="y-wrap" >
    <router-view :style="{ width: !isMobile ? '100%' : '415px' }" class="my-home"/>
    <!-- <Myhome :style="{ width: !isMobile ? '100%' : '415px' }" class="my-home"/> -->
  </div>
</template>
<script >
export default {
  mounted() {
    document.title = "360 Tài chính";
  },
  name: 'App',
  data() {
    return {
      isMobile: false,
      widths:'415'
    };
  },
  created() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() { // 在Vue 3中使用beforeUnmount替代beforeDestroy
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth >= 415;

      console.log("dfsdfsdfsdfsdf");
    }
  },
  components: {
    // Myhome
  }
};
</script>
<style>
body {
  margin: 0;
  background-color: rgb(223, 229, 237);
}
input {
  font-size: 16px; /* 或更大 */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
 
  margin: 0; /* 移除默认边距 */
  padding: 0;
  height: 100%;
  margin-top: 10px;
  
}
.my-home {
width: 415px;
padding: 0;
margin: 0;
  /* ... */
}


.y-wrap {
  display: flex;
  justify-content: center; /* 修改这里 */
  align-items: flex-start; /* 如果您希望子项从顶部开始对齐 */
  height: 100%;
  margin-top: 0;

}
.y-qrcode{
  margin-top: 200px;
  margin-left: 10px;
  margin-right: 100px;
}
.canvas{
  width: 200px;
  height: 200px;
}
</style>
