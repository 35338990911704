<template>
  <div class="container">
    <div class="back-button">
      <!-- 使用 router-link 跳转到主页 -->
      <router-link to="/"><img style="width: 25px;   height: 25px;" src="@/assets/ImageChinese/back.jpg"></router-link>
      <!-- 或者使用按钮点击事件触发跳转 -->
      <!-- <button @click="goBack">返回</button> -->
    </div>
    <div class="image-wrapper">
      <img :src="messageImage" alt="Image"/>
    </div>
  <div>
    <p class="text-center Text">{{message_A}}</p>
  </div>
    <div class="text-wrapper">
      <p class="text-center TextA">{{message_B}}</p>
      <p class="text-center TextB">{{message_C}}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent,ref,computed} from 'vue';
import { useRoute } from 'vue-router';
import Examineing from '@/assets/lodding.jpg'; 
import Examine_success from '@/assets/success.jpg'; 
import Examine_fail from '@/assets/fail.jpg'; 

export default defineComponent({
  setup() {
    const route = useRoute();
    const paramValue = route.params.param;
    const messageImage = computed(() => {
  switch (paramValue) {
    case 'success':
      return Examine_success;
    case 'fail':
      return Examine_fail;
      case 'lodding':
      return Examineing;
    default:
      return Examineing;
  }
})
console.log('传送',paramValue);
    const message_A = ref('Vui lòng đợi, hệ thông đang xét duyệt !');
    const message_B = ref('Nộp Hồ Sơ Thành Công, Vui Lòng chờ Xét Duyệt  !');
    const message_C = ref('Việc xét duyệt hồ sơ vay tài chính sẽ diễn ra trong một ngày, kết quả sẽ được thông báo bằng SMS trên số điện thoại khách hàng đã đăng ký trong hồ sơ, vui lòng chờ kết quả từ hệ thống, xin cám ơn !!!');
if(paramValue == 'success'){
     message_A.value = 'Chúc mừng khách hàng, hồ sơ đã được xét duyệt thành công !';
}else if(paramValue == 'fail'){
     message_A.value = 'Xin lỗi, thông tin đăng ký hồ sơ của khách hàng bị lỗi, xin vui lòng liên hệ bộ phận CSKH để được hỗ trợ !';
}else{
   message_A.value = 'Vui lòng đợi, hệ thông đang xét duyệt !';
}

    return {
      paramValue,
      messageImage,
      message_A,
      message_B,
      message_C
    };
  },
});
</script>
<style>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: rgb(223, 229, 237);
}

.back-button {
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  align-items: flex-start;
}

.image-wrapper {
  text-align: center;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.image-wrapper img {
  max-width: 100%;
  height: auto;
}
.text-wrapper {
  text-align: center;
}

.text-center {
  text-align: center;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
}
.TextA{
  font-size: 13px;
  font-weight: 800;
  color: rgb(72, 71, 71);
}
.TextB{
  font-size: 12px;
  font-weight: 300;
  color: gray;
}
.Text{
  font-size: 18px;
  font-weight: 800;
  position: absolute;
  top: 61%; /* 将文本垂直居中 */
  left: 50%; /* 将文本水平居中 */
  transform: translate(-50%, -50%); /* 确保文本完全居中 */
  color: rgb(4, 4, 4); 
}
</style>